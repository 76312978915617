import * as EliceApi from "@elice/elice-api";
import config from "../config";

const defaultConfigOptions = {
  basePath: config.apiBaseUrl,
  orgNameShort: "academy"
};

const _eliceApi = {
  configOptions: {
    ...defaultConfigOptions
  },
  api: new EliceApi.DefaultApi(new EliceApi.Configuration(defaultConfigOptions))
};

export function setSessionkey(sessionkey) {
  _eliceApi.configOptions.accessToken = sessionkey;
  _eliceApi.api = new EliceApi.DefaultApi(new EliceApi.Configuration(_eliceApi.configOptions));
}

export function setOrgNameShort(orgNameShort) {
  _eliceApi.configOptions.orgNameShort = orgNameShort;
  _eliceApi.api = new EliceApi.DefaultApi(new EliceApi.Configuration(_eliceApi.configOptions));
}

export function init() {}

export default _eliceApi;
