import React, { useState } from "react";
import { Flex, Spinner } from "@elice/blocks";

import Elice from "../../lib/Api";
import constants from "../../constants/projects";
import ProjectViewer from "./ProjectViewer";
import "./GitRepo.scss";

export default function GitRepo(props) {
  const { match } = props;
  const id = match.params.id;
  /**
   * isUploading: in case of fallback. (cannot listen to upload complete here)
   * files: cache for already requested fileNames
   */
  const [isUploading, setIsUploading] = useState(false);
  const files = {};

  const getGitRepoFileList = async id => {
    try {
      const response = await Elice.api.getResourceGitRepo({
        gitRepoId: id
      });

      const status = response.gitRepo.status;
      if (status === constants.gitRepo.status.uploading) {
        setIsUploading(true);
        return [];
      } else {
        return response.gitRepo.filelist; // status === completed
      }
    } catch (e) {
      return [];
    }
  };

  const getGitRepoFile = async (id, fileName) => {
    if (fileName in files) {
      return files[fileName];
    }

    try {
      const response = await Elice.api.getResourceGitRepoFile({
        gitRepoId: id,
        filename: fileName
      });

      files[fileName] = response.exerciseFile;
      return response.exerciseFile;
    } catch (e) {
      return "";
    }
  };

  return (
    <Flex justify="center" align="center" className="projects-git-repo">
      {!isUploading ? (
        <ProjectViewer id={id} getFileList={getGitRepoFileList} getFile={getGitRepoFile} />
      ) : (
        <Spinner fill size="large" />
      )}
    </Flex>
  );
}
