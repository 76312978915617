export default [
  "jpeg",
  "jfif",
  "jpg",
  "exif",
  "tiff",
  "liff",
  "gif",
  "bmp",
  "png",
  "ppm",
  "pgm",
  "pbm",
  "pnm",
  "mng",
  "apng",
  "hdr",
  "heif",
  "bpg",
  "ico"
];
