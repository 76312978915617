import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { Text, Button, Vspace, Flex, Resizable, Markdown } from "@elice/blocks";

import Files from "../common/Files";
import fileTypes from "../../constants/fileTypes";
import imageTypes from "../../constants/imageTypes";
import SharedCodeEditor from "../common/SharedCodeEditor";
import "./ProjectViewer.scss";

function ProjectViewer(props) {
  const prefixCls = "project-viewer";
  const { id, getFileList, getFile } = props;
  /**
   * React Hook States
   * @sharedCodeEditorRef - used for nullity check
   * @filesToOpen - file opening queue, only used if API call finishes earlier than Editor.
   *    If declared as 'let'( ex. let filesToOpen = []; const processFilesToOpen = .. ), it is redeclared on every render, and updates 'openFile' function due to dependency. And this would infinitely call useEffect()
   */
  const [fileList, setFileList] = useState([]);
  const [activeFilePath, setActiveFilePath] = useState(null);
  const sharedCodeEditorRef = useRef();
  const [activeFile, setActiveFile] = useState("");
  const [editorOptions, setEditorOptions] = useState({
    readOnly: true,
    automaticLayout: true,
    theme: "vs-dark",
    minimap: { enabled: false },
    scrollbar: { horizontalScrollbarSize: 5 },
    wordWrap: true,
  });
  const [editorFileType, setEditorFileType] = useState(null);

  const getLanguageModeFromExtension = (fileExtension) => {
    for (let i = 0; i < fileTypes.length; i++) {
      const mode = fileTypes[i].mode;
      const extensions = fileTypes[i].ext ? fileTypes[i].ext : [];
      for (let j = 0; j < extensions.length; j++) {
        if (fileExtension === extensions[j]) {
          return mode;
        }
      }
    }
  };

  const chooseEditorAndLanguageOption = (fileName, file) => {
    let fileExtension, languageMode, isImage; // may not exist
    const splitFileName = fileName.split(".");
    if (splitFileName.length >= 2) {
      fileExtension = splitFileName[splitFileName.length - 1];
      languageMode = getLanguageModeFromExtension(fileExtension);
      isImage = imageTypes.includes(fileExtension.toLowerCase());
    }

    // if fileContent is URL
    if (file.url) {
      if (isImage) {
        setEditorFileType("image");
        return;
      } else {
        setEditorFileType("unsupported");
        return;
      }
    }

    // normal fileContent, but those require special viewer (ex. markdown)
    if (languageMode === "markdown") {
      setEditorFileType("markdown");
    } else {
      setEditorFileType("code");
    }

    // added if-statement, since monaco-editor does not yet support mixed-language ex. 'htmlmixed'
    if (languageMode && languageMode.startsWith("html")) {
      languageMode = "html";
    }
    setEditorOptions((prevState) => ({
      ...prevState,
      language: languageMode,
    }));
  };

  /**
   * chooseInitialActiveFilePath: sets 'README.md' as initialFile.
   *  - Need to support different default files for different languages. ex) 'main.py' for python
   */
  const chooseInitialActiveFilePath = (fileList) => {
    for (let i = 0; i < fileList.length; i++) {
      const filePath = fileList[i];
      if (filePath.toLowerCase().endsWith("readme.md")) {
        return filePath;
      }
    }

    for (let i = 0; i < fileList.length; i++) {
      const filePath = fileList[i];
      if (!filePath.includes("/")) {
        return filePath;
      }
    }

    return null;
  };

  /**
   * openFile: checks if sharedCodeEditor exists, before opening the file
   */
  const openFile = async (id, fileName) => {
    const file = await getFile(id, fileName);

    setActiveFile(file);
    setActiveFilePath(fileName);
    chooseEditorAndLanguageOption(fileName, file);
  };

  useEffect(() => {
    async function initialSetUp() {
      const newFileList = await getFileList(id);
      setFileList(newFileList);

      const initialFilePath = chooseInitialActiveFilePath(newFileList);
      if (initialFilePath !== null) {
        openFile(id, initialFilePath);
      }
    }

    initialSetUp();
    // eslint-disable-next-line
  }, [id]);

  console.log("editorFileType", editorFileType);
  console.log("activeFile", activeFile);
  console.log("activeFilePath", activeFilePath);

  return (
    <Flex className={prefixCls}>
      <Resizable
        gripPosition="right"
        initialSpace={240}
        minSpace={60}
        hideIndicator
        isCollapsable={true}
        resetSpace={240}
        className={`${prefixCls}__file-tree`}
        collapsedIconName="icon-files"
      >
        <Files
          title="root"
          exerciseImageTaskFileList={fileList}
          exerciseRoomFileList={[]}
          activeFilePath={activeFilePath}
          id={id}
          openFile={openFile}
        />
      </Resizable>
      <Flex
        column
        justify="center"
        align="center"
        className={`${prefixCls}__file-viewer`}
      >
        {editorFileType === "markdown" && (
          <Markdown className={classnames(`${prefixCls}__markdown`)}>
            {activeFile.content}
          </Markdown>
        )}
        {editorFileType === "image" && (
          <img alt={activeFile.url} src={activeFile.url} />
        )}
        {editorFileType === "unsupported" && (
          <>
            <Text role="white" size="jumbo">
              <i className="icon-alert" />
            </Text>
            <Vspace height={1} />
            <Text center role="white" size="small" lineHeight={2}>
              <center>
                미리보기를 표시할 수 없는 형식의 파일입니다.
                <br />
                파일을 보려면 다운로드 받아 열 수 있는 앱을 선택하세요.
              </center>
            </Text>
            <Vspace height={1} />
            <Button
              onClick={() => {
                window.location.href = activeFile.url;
              }}
              size="small"
            >
              다운로드
            </Button>
          </>
        )}
        {editorFileType === "code" && (
          <SharedCodeEditor
            ref={sharedCodeEditorRef}
            className={`${prefixCls}__shared-code-editor`}
            value={activeFile.content}
            options={editorOptions}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default ProjectViewer;
