import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Filetree } from "@elice/blocks";

import * as FileTreeBuilder from "../../lib/filetreeBuilder.js";
import "./Files.scss";

function Files(props) {
  const [activeFilePath, setActiveFilePath] = useState("/");
  const [expandedDirectories, setExpandedDirectories] = useState(["/"]);

  /**
   * tree: object for FileTreeBuilder
   * @param {String} title - root directory variable for FileTreeBuilder to keep.
   * @param {String} pathes - files from exercise image
   * @param {String} userPathes - files that THIS user created
   */
  const { title, exerciseImageTaskFileList, exerciseRoomFileList } = props;
  const tree = FileTreeBuilder.build({
    title: title ? title : "root",
    pathes: exerciseImageTaskFileList ? exerciseImageTaskFileList : [],
    userPathes: exerciseRoomFileList ? exerciseRoomFileList : []
  });

  useEffect(() => {
    const { activeFilePath } = props;
    setActiveFilePath(activeFilePath);
  }, [props]);

  const handleFileClick = path => {
    const { id, openFile } = props;
    let requestId = id ? id : 0;
    setActiveFilePath(path);
    openFile(requestId, path);
  };

  const handleDirectoryClick = path => {
    if (expandedDirectories.includes(path)) {
      const newExpandedDirectories = [...expandedDirectories];
      newExpandedDirectories.splice(newExpandedDirectories.indexOf(path), 1);
      setExpandedDirectories(newExpandedDirectories);
    } else {
      setExpandedDirectories([...expandedDirectories, path]);
    }
  };

  return (
    <Filetree
      filetree={tree}
      activePath={activeFilePath}
      expandedPathes={expandedDirectories}
      onDirectoryClick={v => handleDirectoryClick(v)}
      onFileClick={v => handleFileClick(v)}
    />
  );
}

Files.propTypes = {
  title: PropTypes.string,
  exerciseImageTaskFileList: PropTypes.arrayOf(PropTypes.string),
  exerciseRoomFileList: PropTypes.arrayOf(PropTypes.string),
  activeFilePath: PropTypes.string,
  id: PropTypes.number,
  openFile: PropTypes.func.isRequired
};

export default Files;
