export default {
  gitRepo: {
    status: {
      uploading: 0,
      completed: 2
    }
  },
  zipFile: {
    status: {
      completed: 2
    }
  }
};
