import React from "react";

import Elice from "../../lib/Api";
import ProjectViewer from "./ProjectViewer";

export default function ZipFile(props) {
  const { match } = props;
  const id = match.params.id;
  /**
   * files: cache for already requested fileNames
   */
  const files = {};

  const getZipFileFileList = async id => {
    try {
      const response = await Elice.api.getResourceZipFile({
        zipFileId: id
      });

      return response.zipFile.filelist;
    } catch (e) {
      return [];
    }
  };

  const getZipFileFile = async (id, fileName) => {
    if (fileName in files) {
      return files[fileName];
    }

    try {
      const response = await Elice.api.getResourceZipFileFile({
        zipFileId: id,
        filename: fileName
      });

      files[fileName] = response.exerciseFile;
      return response.exerciseFile;
    } catch (e) {
      return "";
    }
  };

  return <ProjectViewer id={id} getFileList={getZipFileFileList} getFile={getZipFileFile} />;
}
