/**
 * An object that represents a file tree structure
 * composed of directories and files.
 * @typedef {object} FileTree
 * @property {string} title - A title of this node.
 * @property {object} dirs - An object that contains FileTree objects
 * representing subdirectories, with their titles as keys.
 * @property {array} dirNames - An array of titles of subdirectories as strings.
 * @property {array} files - An array of files in the directory in a form of
 * { filename: <filename>, path: <path> }.
 */

/**
 * A module that provides functions to build file structure trees
 * given list of the file pathes.
 * @namespace lib
 * @module FileTreeBuilder
 */

function _createNode(isDirectory, path, title) {
  return Object.assign(
    {
      path,
      isDirectory,
      isReadonly: false,
      isRemovable: true,
      contents: [],
    },
    title ? { title } : {}
  );
}

function _createDirectoryNode(path, title) {
  return _createNode(true, path, title);
}

function _createFileNode(path) {
  return _createNode(false, path);
}

function _getParentPath(path) {
  return path.includes("/") ? path.slice(0, path.lastIndexOf("/")) : null;
}

function _setDirectoriesRemovable(node) {
  for (const child of node.contents) {
    if (child.isDirectory) {
      if (!_setDirectoriesRemovable(child)) {
        node.isRemovable = false;
      }
    } else if (!child.isRemovable) {
      node.isRemovable = false;
    }
  }
  return node.isRemovable;
}

/**
 * This function builds a tree-like recursive Javascript object that
 * represents the file tree of the files structured based on the
 * given array of file pathes.
 * @param {string} title - A title of the file tree.
 * @param {array} pathes - An array of file pathes in a string form.
 * @returns {FileTree} A Filetree object that is built based on the
 * given file pathes.
 */
export function build(params) {
  const { title, pathes, userPathes } = params;
  const tree = _createDirectoryNode("/", title);
  const directoryNodes = {};

  const getParentNode = (path) => {
    const parentPath = _getParentPath(path);
    if (!parentPath) {
      return tree;
    } else if (directoryNodes[parentPath]) {
      return directoryNodes[parentPath];
    } else {
      const grandParentNode = getParentNode(parentPath);
      const parentNode = _createDirectoryNode(parentPath);
      grandParentNode.contents.push(parentNode);
      directoryNodes[parentPath] = parentNode;
      return parentNode;
    }
  };

  const allPathes = pathes.concat(userPathes);
  allPathes.sort();
  for (const path of allPathes) {
    let parentNode = null;
    let node = null;
    if (path.endsWith("/")) {
      const cleanPath = path.slice(0, path.length - 1);
      parentNode = getParentNode(cleanPath);
      node = _createDirectoryNode(cleanPath);
      directoryNodes[cleanPath] = node;
    } else {
      parentNode = getParentNode(path);
      node = _createFileNode(path);
      if (pathes.includes(path)) {
        node.isRemovable = false;
      }
    }
    parentNode.contents.push(node);
  }

  _setDirectoriesRemovable(tree);

  return tree;
}
