import React, { useRef } from "react";
import Editor from "@monaco-editor/react";

function SharedCodeEditor(props) {
  const editorRef = useRef();

  const handleEditorDidMount = (_, editor) => {
    if (props.onEditorLoad) {
      props.onEditorLoad();
    }

    editorRef.current = editor;
  };

  const handleEditorChange = (ev, value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <>
      <Editor
        height="100%"
        value={props.value}
        language={props.options?.language}
        onChange={handleEditorChange}
        editorDidMount={handleEditorDidMount}
        theme="dark"
      />
    </>
  );
}

export default SharedCodeEditor;

// import React, { Component } from "react";
// import Editor from "@monaco-editor/react";
// // import * as Monaco from "monaco-editor";

// export default class SharedCodeEditor extends Component {
//   componentDidMount() {
//     // const { value, options, onEditorLoad } = this.props;
//     // this.monaco = Monaco.editor.create(this.sceditorRef, options);
//     // this.sceditor = new SharedCodeEditorLib(this.monaco);
//     // this.sceditor.on("change", e => {
//     //   this.handleChange(e);
//     // });
//     // this.sceditor.setValue(value);
//     // if (onEditorLoad) {
//     //   // React component's ref callback is called after componentDidMount(),
//     //   // thus waiting for the parent component to get ref for this component.
//     //   setTimeout(() => onEditorLoad(), 0);
//     // }
//   }

//   componentDidUpdate(prevProps, prevState) {
//     // const { value, options } = this.props;
//     // const editorValue = this.sceditor.editor.getValue();
//     // if (editorValue !== value) {
//     //   this.sceditor.setValue(value);
//     // }
//     // if (prevProps.options !== options) {
//     //   const model = this.sceditor.editor.getModel();
//     //   Monaco.editor.setModelLanguage(model, options.language);
//     // }
//   }

//   handleChange(e) {
//     // const { onChange } = this.props;
//     // if (onChange) {
//     //   onChange(this.sceditor.editor.getValue());
//     // }
//   }

//   render() {
//     return (
//       <div className={`shared-code-editor ${this.props.className}`}>
//         <Editor editorDidMount={handleEditorDidMount} />
//       </div>
//     );
//   }
// }
