import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";

import * as Elice from "./lib/Api";
// import Elixercise from "./components/elixercise/Elixercise";
import GitRepo from "./components/projects/GitRepo";
import ZipFile from "./components/projects/ZipFile";
import "./App.css";

class App extends Component {
  componentWillMount() {
    Elice.init();
  }

  render() {
    return (
      <Router>
        <div className="App flex-rows-container">
          <Route path="/projects/git-repo/:id" component={GitRepo} />
          <Route path="/projects/zip-file/:id" component={ZipFile} />
          {/* <Route exact path="/" component={Elixercise} /> */}
        </div>
      </Router>
    );
  }
}

export default App;
